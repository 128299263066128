import "./assets/app.css";

import themeColors from './themeColors.json';

// @ts-ignore-next-line
import Toastify from 'toastify-js';

import "@melloware/coloris/dist/coloris.css"
import Coloris from "@melloware/coloris"

import 'iconify-icon';
import 'phoenix_html';
import { Socket } from 'phoenix';
import { LiveSocket } from 'phoenix_live_view';
// @ts-ignore-next-line
import topbar from 'topbar';

import CopyToClipboard from "./hooks/CopyToClipboard";
import DateTimeInput, * as DTUtil from "./hooks/DateTimeInput";
import TokenInput from "./hooks/TokenInput";
import ComboboxInput from "./hooks/ComboboxInput";
import PPPasswordInput from "./hooks/PPPasswordInput";
import LeafletLocationInput from "./hooks/LeafletLocationInput";
import RichTextEditor from "./hooks/RichTextEditor";
import { EnableNotificationsPopup, NotificationSettingsButtons } from "./hooks/EnableNotificationsPopup";
import { PopupButton } from "./hooks/PopupButton";
import { SortableHook } from "./hooks/Sortable";

declare global {
  interface Window {
    swReg: ServiceWorkerRegistration;
  }
}

let Hooks = {
  DateTimeInput,
  TokenInput,
  ComboboxInput,
  PPPasswordInput,
  RichTextEditor,
  LeafletLocationInput,
  CopyToClipboard,
  EnableNotificationsPopup,
  NotificationSettingsButtons,
  PopupButton,
  SortableHook,
};

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/assets/firebase-push-sw.js')
    .then((registration) => {
      // console.log("SW registered:", registration)
      window.swReg = registration
    }, (err) => {
      console.error('SW not registered:', err)
    });
  });
}


// @ts-ignore-next-line
let csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content")
let liveSocket = new LiveSocket("/live", Socket, {
  params: { _csrf_token: csrfToken },
  hooks: Hooks,
  dom: {
    onBeforeElUpdated: (from, to): boolean => {
      if (DTUtil.onBeforeElUpdatedCb(from, to)) {
        return true;
      }

      return true;
    }
  },
})

Coloris.init()
Coloris({
  el: "input.color-picker",
  themeMode: 'auto',
  alpha: false,
})

liveSocket.connect()

topbar.config({ barColors: { 0: themeColors.primary }, shadowColor: 'rgba(0, 0, 0, 0.3)' })
window.addEventListener('phx:page-loading-start', () => topbar.show());
window.addEventListener('phx:page-loading-stop', () => topbar.hide());

// @ts-ignore-next-line
window.liveSocket = liveSocket

// @ts-ignore-next-line
window.addEventListener('phx:show-toast', ({ detail }) => {
  // @ts-ignore-next-line
  const { message, type } = detail
  Toastify({
    text: message,
    close: true,
    className: type,
  }).showToast();
})

const attendanceSaveListener = (evt: any) => {
  // @ts-ignore-next-line
  const { event_id } = evt.detail;
  const btn = <HTMLButtonElement>evt.target;
  const holder = <HTMLDivElement>(btn.parentNode);

  btn.classList.add('selected')
  holder.classList.add('loading', 'disabled')

  const stopLoading = (e: unknown) => {
    // @ts-ignore-next-line
    if (!e.detail.success) {
      btn.classList.remove('selected')
    }
    // holder.classList.remove('loading', 'disabled')
  }

  window.addEventListener(`phx:stop-loading:${event_id}`, stopLoading, { once: true })
}

window.addEventListener("pp:save_application", attendanceSaveListener)
window.addEventListener("pp:save_status", attendanceSaveListener)

window.addEventListener("pp:edit-attendance-click", evt => {
  const btn = <HTMLButtonElement>evt.target;
  const holder = <HTMLDivElement>btn.parentNode
  for (let c = 0; c < holder.childElementCount; c++) {
    const childBtn = holder.children[c];
    childBtn.classList.remove('hide')
  }
  btn.classList.add('hide')
})
